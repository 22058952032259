import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Alert, CircularProgress, Snackbar } from '@mui/material'
import { useEffect, useState } from 'react'

import './styles.css'
import { SimulatedTransaction } from '../../entities/transaction'
import apiPay from '../../services/apiPay'
import { Form } from '../../components/Form'
import { useNavigate } from 'react-router'
import { TableInstallments } from '../../components/TableInstallments'

const paymentSchema = z.object({
  amount: z.string().nonempty({ message: 'O valor é obrigatório' }),
  numberInstallments: z
    .string()
    .nonempty({ message: 'O número de parcelas é obrigatório' }),
})

type PaymentSchema = z.infer<typeof paymentSchema>

export default function LinkPayment() {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [transactions, setTransactions] = useState<SimulatedTransaction[]>([
    {
      numberInstallments: 4,
      amountReceived: 0,
      totalPayment: 0,
      installmentValue: 0,
      amount: 0,
      rate: 0,
    },
  ])
  const paymentForm = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
  })

  const { watch, handleSubmit, setValue, formState, reset } = paymentForm

  const setInstallments = (value: string) => {
    setValue('numberInstallments', String(value))
  }

  const amount = watch('amount')

  useEffect(() => {
    ;(async () => {
      if (amount) {
        const { status, data } = await apiPay.post<{
          simulations: SimulatedTransaction[]
        }>('/transactions/simulate', {
          amount: Number(amount.replace(',', '.')),
          transactionType: 'ticket',
        })

        if (status === 200 && data) {
          setTransactions(
            data?.simulations.sort((a, b) => {
              if (a.numberInstallments < b.numberInstallments) return -1
              return 1
            }),
          )
        }
      }
    })()
  }, [amount])

  const onSubmit = async (data: PaymentSchema) => {
    try {
      const body = {
        amount: Number(data.amount.replace(',', '.')),
        transactionType: 'ticket',
        numberInstallments: Number(data.numberInstallments),
      }

      const { status, data: response } = await apiPay.post(
        '/transactions',
        body,
      )

      if (status === 401) {
        navigate('/login')
        return
      }

      if (status !== 201) {
        setErrorMessage('Houve um erro inesperado, tente novamente')
        return
      }

      setSuccess(true)
      reset()
      setTransactions([
        {
          numberInstallments: 1,
          amountReceived: 0,
          totalPayment: 0,
          installmentValue: 0,
          amount: 0,
          rate: 0,
        },
      ])

      const transactionCode = response.code
      navigate(`/link-payment/send/${transactionCode}`)
    } catch (error) {
      setErrorMessage('Houve um erro inesperado, tente novamente')
    }
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSuccess(false)
    setErrorMessage('')
  }

  return (
    <div className="form-payment">
      <h2>Parcelar conta</h2>
      <FormProvider {...paymentForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <Form.Field className="w-100 mg-bottom-20">
              <Form.Input
                name="amount"
                label="Valor da venda"
                size="small"
                className="w-100"
                color="success"
                autoComplete="off"
                error={!!formState.errors.amount?.message}
                id="outlined-error"
                inputProps={{
                  inputMode: 'decimal',
                }}
              />
            </Form.Field>
            <TableInstallments
              transactions={transactions}
              setInsallments={setInstallments}
              className={`${
                formState.errors.numberInstallments?.message ? 'error' : ''
              } mobile`}
            />
            <button type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Gerar link de pagamento'
              )}
            </button>
          </section>
          <TableInstallments
            transactions={transactions}
            setInsallments={setInstallments}
            className={`${
              formState.errors.numberInstallments?.message ? 'error' : ''
            } desktop`}
          />
        </form>
      </FormProvider>
      <Snackbar
        open={!!errorMessage || success}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={errorMessage ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {errorMessage || 'Transação criada com sucesso, aguarde o pagamento'}
        </Alert>
      </Snackbar>
    </div>
  )
}
