import { ReactNode } from 'react'
import { MenuMobile } from './MenuMobile'
import { useMediaQuery } from '@mui/material'
import { MenuDesktop } from './MenuDesktop'

export function Menu({ children }: { children: ReactNode }) {
  const isMobile = useMediaQuery('(max-width: 1024px)')

  if (isMobile) {
    return <MenuMobile>{children}</MenuMobile>
  }
  return <MenuDesktop>{children}</MenuDesktop>
}
