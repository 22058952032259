import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material'

interface CustomProps {
  name: string
}

type RadioGroupProps = MuiRadioGroupProps & CustomProps

export function RadioGroups(props: RadioGroupProps) {
  return <MuiRadioGroup aria-labelledby="demo-error-radios" {...props} />
}
