import { Skeleton, TextField } from '@mui/material'

interface Props {
  loading: boolean
  value: string
  label: string
}

export function TicketField({ label, loading, value }: Props) {
  if (loading) {
    return <Skeleton animation="wave" className="skeleton" height={60} />
  }
  return (
    <TextField
      disabled
      id="outlined-disabled"
      label={label}
      defaultValue={value}
    />
  )
}
