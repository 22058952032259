import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router'

import './styles.css'
import { BsBoxArrowRight } from 'react-icons/bs'

export default function Services() {
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const navigate = useNavigate()

  if (!isMobile) {
    navigate('/')
  }

  return (
    <main className="container-services">
      <h2>Serviços</h2>
      <div className="container-buttons">
        <button onClick={() => navigate('/payment-ticket')}>
          Parcele contas
          <BsBoxArrowRight size={25} />
        </button>
        <button onClick={() => navigate('/link-payment')}>
          Link de pagamento para suas vendas
          <BsBoxArrowRight size={25} />
        </button>
        <button onClick={() => navigate('/tickets')}>
          Contas dos clientes
          <BsBoxArrowRight size={25} />
        </button>
      </div>
    </main>
  )
}
