import { useState } from 'react'
import apiPay from '../../services/apiPay'
import { useParams } from 'react-router'
import { Ticket } from '../../entities/ticket'

import './styles.css'
import { Alert, Box, CircularProgress, Modal, Snackbar } from '@mui/material'
import { formatPrice } from '../../helpers/format'
import { FaRegFileAlt } from 'react-icons/fa'
import { TicketField } from './ticketField'
import { useQuery } from 'react-query'

const formatStatus: Record<string, string> = {
  waiting: 'Aguardando',
  success: 'Pago',
  expired: 'Expirado',
  denied: 'Negado',
}

const ticketStatus: Record<string, string> = {
  waiting: 'aguardando',
  done: 'paga',
}

export default function TicketDetails() {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)

  const handleCloseModalConfirm = () => {
    setIsOpenModalConfirm(false)
  }

  const { data: ticket, isFetching: loadTicketDetails } = useQuery(
    `ticket:${id}`,
    async () => {
      const { data } = await apiPay.get<Ticket>(`/tickets/${id}`)

      return data
    },
    {
      staleTime: 1000 * 30 * 60,
    },
  )

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSuccess(false)
  }

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setMessageError('')
  }

  const confirmPayment = async () => {
    try {
      setLoading(true)
      const { status, data } = await apiPay.patch(`/tickets/${id}`)
      if (status !== 202) {
        if (data?.Message.includes('ticket already paid')) {
          setMessageError('Essa conta já foi confirmada o seu pagamento')
          return
        }
        setMessageError('Houve um erro inesperado, tente novamente')
        return
      }

      setSuccess(true)
    } catch (error) {
      setMessageError('Houve um erro inesperado, tente novamente')
    } finally {
      setLoading(false)
      handleCloseModalConfirm()
    }
  }

  return (
    <div className="container-ticket-details">
      <h2>Detalhes da conta</h2>

      <div className="details">
        <TicketField
          loading={loadTicketDetails}
          label="Valor da conta"
          value={formatPrice(ticket?.amountTicket || 0)}
        />
        <TicketField
          loading={loadTicketDetails}
          label="Status da conta"
          value={
            ticket?.transactionPaymentStatus === 'success'
              ? ticketStatus[ticket.ticketStatus] || ''
              : 'cliente ainda não pagou'
          }
        />
      </div>
      <div className="details">
        <TicketField
          loading={loadTicketDetails}
          label="Status do pagamento"
          value={formatStatus[ticket?.transactionPaymentStatus || ''] || ''}
        />
        <TicketField
          loading={loadTicketDetails}
          label="Valor à receber"
          value={formatPrice(ticket?.amountReceived || 0)}
        />
      </div>
      <div className="details">
        <TicketField
          loading={loadTicketDetails}
          label="Nome do cliente"
          value={ticket?.customerName || ''}
        />
        <TicketField
          loading={loadTicketDetails}
          label="Número do cliente"
          value={ticket?.customerPhoneNumber || ''}
        />
      </div>
      <div className="files">
        {ticket?.filesUrl?.map((file) => (
          <a href={file} target="_blank" rel="noreferrer" key={file}>
            <FaRegFileAlt size={60} />
            baixar arquivo
          </a>
        ))}
      </div>
      {ticket?.ticketStatus === 'waiting' &&
        ticket?.transactionPaymentStatus === 'success' && (
          <div className="container-button">
            <button
              onClick={() => setIsOpenModalConfirm(true)}
              disabled={loading || success}
            >
              {loading ? (
                <CircularProgress size={'1rem'} />
              ) : (
                'Confirmar pagamento do boleto'
              )}
            </button>
          </div>
        )}

      <Snackbar
        open={success}
        autoHideDuration={5000}
        onClose={handleCloseSuccess}
      >
        <Alert
          severity={'success'}
          sx={{ width: '100%', fontSize: 18, textAlign: 'center' }}
          onClose={handleCloseSuccess}
        >
          Pagamento efetuado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!messageError}
        autoHideDuration={5000}
        onClose={handleCloseError}
      >
        <Alert
          severity={'error'}
          sx={{ width: '100%', fontSize: 18, textAlign: 'center' }}
          onClose={handleCloseError}
        >
          {messageError}
        </Alert>
      </Snackbar>
      <Modal
        open={isOpenModalConfirm}
        onClose={handleCloseModalConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="container-modal-confirm">
          <h3>Deseja confirmar o pagamento desse boleto?</h3>
          <div className="row-buttons">
            <button onClick={confirmPayment} disabled={loading}>
              {loading ? (
                <CircularProgress size={'1rem'} />
              ) : (
                'Confirmar pagamento'
              )}
            </button>
            <button onClick={handleCloseModalConfirm} disabled={loading}>
              {loading ? <CircularProgress size={'1rem'} /> : 'Cancelar'}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
