import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Divider,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { formatPrice } from '../../helpers/format'
import { Transaction } from '../../entities/transaction'

import { IoMdClose } from 'react-icons/io'
import { GoVerified } from 'react-icons/go'
import { ImSpinner6 } from 'react-icons/im'
import { MdTimerOff } from 'react-icons/md'
import { useState } from 'react'

interface RowProps {
  transaction: Transaction
}

const formatStatus: Record<
  string,
  { icon: () => JSX.Element; statusPt: string }
> = {
  waiting: {
    icon: () => <ImSpinner6 color="#1890ff" size={26} />,
    statusPt: 'Aguardando',
  },
  success: {
    icon: () => <GoVerified color="#09b40d" size={26} />,
    statusPt: 'Pago',
  },
  expired: {
    icon: () => <MdTimerOff color="#d34242" size={26} />,
    statusPt: 'Expirado',
  },
  denied: {
    icon: () => <IoMdClose color="#d34242" size={26} />,
    statusPt: 'Negado',
  },
}

const formatReceiptStatus: Record<string, string> = {
  blocked: 'Aguardando pagamento',
  success: 'Recebido',
  waiting: 'Aguardando',
}

const RowTableCell = styled(TableCell)(() => ({
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 10,
  paddingBottom: 10,
}))

export function RowTransaction({ transaction }: RowProps) {
  const [open, setOpen] = useState(false)
  const matches = useMediaQuery('(min-width:768px)')

  const prefixType = transaction.transactionType === 'ticket' ? 'TIC' : 'PAY'

  const [year, month, day] = transaction.createdAt.split('T')[0].split('-')
  const { icon, statusPt } = formatStatus[transaction.statusPayment] || {
    icon: () => <IoMdClose color="#d34242" size={16} />,
    statusPt: 'Negado',
  }

  const statusReceipt = formatReceiptStatus[transaction.statusReceipt] || ''
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {matches && (
          <RowTableCell align="center">{`${prefixType}_${
            transaction.id.split('-')[0]
          }`}</RowTableCell>
        )}
        {matches && (
          <RowTableCell align="center">{`${day}/${month}/${year}`}</RowTableCell>
        )}
        {!matches && (
          <RowTableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </RowTableCell>
        )}
        {matches ? (
          <RowTableCell align="center">
            <Tooltip title={statusPt} placement="top">
              {icon()}
            </Tooltip>
          </RowTableCell>
        ) : (
          <RowTableCell align="center">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: 'auto',
                marginLeft: '20%',
              }}
            >
              {icon()}
              <p style={{ marginLeft: 10 }}>{statusPt}</p>
            </div>
          </RowTableCell>
        )}
        {matches && <RowTableCell align="center">{statusReceipt}</RowTableCell>}
        <RowTableCell align="center">
          {formatPrice(transaction.amountSale)}
        </RowTableCell>
        {matches && (
          <RowTableCell align="center">
            {formatPrice(transaction.amountReceivable)}
          </RowTableCell>
        )}
      </TableRow>
      <TableRow sx={{ width: '100%' }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Box sx={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Código"
                    secondary={`${prefixType}_${transaction.id.split('-')[0]}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Data"
                    secondary={`${day}/${month}/${year}`}
                  />
                </ListItem>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Conciliada"
                    secondary={statusReceipt}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Valor Liberado"
                    secondary={formatPrice(transaction.amountReceivable)}
                  />
                </ListItem>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
