import { z } from 'zod'
import './styles.css'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Transaction } from '../../entities/transaction'
import apiPay from '../../services/apiPay'

import TransactionsTable from './transactionsTable'
import { FormRangeDate } from '../../components/FormRangeDate'
import { useQuery } from 'react-query'

const listTransactionsSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  statusPayment: z.string(),
})

type ListTransactionsSchema = z.infer<typeof listTransactionsSchema>

export default function ListTransactions() {
  const today = new Date()
  const listTransactionForm = useForm<ListTransactionsSchema>({
    resolver: zodResolver(listTransactionsSchema),
    defaultValues: {
      startDate: new Date(today.setDate(today.getDate() - 7)),
      endDate: new Date(),
      statusPayment: 'select',
    },
  })

  const { watch } = listTransactionForm

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const { data: transactions, isFetching: loading } = useQuery(
    `listTransactions:${startDate.getFullYear()}-${startDate.getMonth() + 1}-${
      startDate.getDate() - 1
    }-${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
      endDate.getDate() + 2
    }`,
    async () => {
      const { data } = await apiPay.get<{
        transactions: Transaction[]
      }>('/transactions', {
        params: {
          startDate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${
            startDate.getDate() - 1
          }`,
          endDate: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
            endDate.getDate() + 2
          }`,
        },
      })

      return data.transactions
    },
    {
      staleTime: 1000 * 60 * 5,
    },
  )

  return (
    <div className="container-transactions">
      <h2>Transações</h2>

      <FormProvider {...listTransactionForm}>
        <FormRangeDate />
      </FormProvider>

      <TransactionsTable transactions={transactions || []} loading={loading} />
    </div>
  )
}
