import { Form } from '../Form'

import './styles.css'

export function FormRangeDate() {
  return (
    <form className="form-range-date">
      <Form.Field className="container-input">
        <Form.Label>Data início</Form.Label>
        <Form.DateInput name="startDate" />
      </Form.Field>
      <Form.Field className="container-input">
        <Form.Label>Data fim</Form.Label>
        <Form.DateInput name="endDate" />
      </Form.Field>
    </form>
  )
}
