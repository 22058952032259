import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ListItem, ListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { formatPrice } from '../../helpers/format'

import { useState } from 'react'
import { Receipt } from '../../entities/receipt'

interface RowProps {
  receipt: Receipt
}

const RowTableCell = styled(TableCell)(() => ({
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 10,
  paddingBottom: 10,
}))

export function RowReceipt({ receipt }: RowProps) {
  const [open, setOpen] = useState(false)

  const [year, month, day] = receipt.createdAt.split('T')[0].split('-')

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <RowTableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </RowTableCell>
        <RowTableCell align="center">{`${
          receipt.id.split('-')[0]
        }`}</RowTableCell>

        <RowTableCell align="center">{`${day}/${month}/${year}`}</RowTableCell>

        <RowTableCell align="center">
          {formatPrice(receipt.amountPaid)}
        </RowTableCell>
      </TableRow>
      <TableRow sx={{ width: '100%' }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Box sx={{ width: '100%' }}>
              <h2 style={{ margin: 0, marginTop: 5 }}>Transações</h2>
              {receipt.transactions.map((transaction) => (
                <div key={transaction.code} style={{ margin: 0 }}>
                  <ListItem sx={{ margin: 0, padding: 0 }}>
                    <ListItemText
                      primary="Codigo"
                      secondary={transaction.code.substring(0, 7)}
                    />
                    <ListItemText
                      primary="Valor recebido"
                      secondary={formatPrice(transaction.amountReceived)}
                    />
                  </ListItem>
                </div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
