export const formatPrice = (price: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price)
}

export const treatStrings = (value: string): string => {
  let string = value
  string = string.replace(/[^\d]/g, '')
  return string
}
