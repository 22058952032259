import { Field } from './Field'
import { Input } from './Input'
import { Select } from './Select'
import { Label } from './Label'
import { ErrorMessage } from './ErrorMessage'
import { RadioGroups } from './RadioGroup'
import { DateInput } from './DateInput'
import { RadioOption } from './RadioOption'

export const Form = {
  Label,
  Field,
  Input,
  Select,
  ErrorMessage,
  RadioGroups,
  RadioOption,
  DateInput,
}
