import { JSX } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router'

interface MenuProps {
  open: boolean
  icon: JSX.Element
  href: string
  name: string
}

export function MenuItem({ open, icon: Icon, href, name }: MenuProps) {
  const navigate = useNavigate()

  return (
    <ListItem
      disablePadding
      sx={{ display: 'block' }}
      onClick={() => {
        navigate(href)
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {Icon}
        </ListItemIcon>
        <ListItemText
          primary={name}
          sx={{
            opacity: open ? 1 : 0,
            fontSize: '5px',
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}
