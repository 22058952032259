import { z } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '../../components/Form'

import Logo from '../../assets/logo.svg'

import './styles.css'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import apiPay from '../../services/apiPay'
import { formatPrice } from '../../helpers/format'
import { Transaction } from '../../entities/transaction'
import { Alert, CircularProgress, Snackbar } from '@mui/material'
import { BsTrashFill } from 'react-icons/bs'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Ticket } from '../../entities/ticket'
import { ModalReceipt } from './modalReceipt'

const paymentSchema = z.object({
  cardName: z
    .string()
    .nonempty({ message: 'Seu nome no cartão é obrigatório' }),
  numberCard: z
    .string()
    .nonempty({ message: 'Seu número do cartão é obrigatório' }),
  expireDate: z
    .string()
    .nonempty({ message: 'Data de expiração é obrigatório' }),
  securityCode: z
    .string()
    .nonempty({ message: 'Código de segurança é obrigatório' }),
})

type PaymentSchema = z.infer<typeof paymentSchema>

const customerSchema = z.object({
  name: z.string().nonempty({ message: 'Seu nome é obrigatório' }),
  phoneNumber: z.string().nonempty({ message: 'Seu telefone é obrigatório' }),
  document: z.string().nonempty({ message: 'Seu documento é obrigatório' }),
})

type CustomerSchema = z.infer<typeof customerSchema>

type TypeForms = 'files' | 'customer' | 'payment'

export default function ClientLinkPayment() {
  const { code } = useParams()
  const [transaction, setTransaction] = useState<Transaction>()
  const [paymentDeniedMessage, setPaymentDeniedMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [transactionAlreadyPaid, setTransactionAlreadyPaid] = useState(false)
  const [transactionExpired, setTransactionExpired] = useState(false)
  const [files, setFiles] = useState<File[]>([])

  const [typeForm, setTypeForm] = useState<TypeForms>()

  const paymentForm = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
  })

  const customerForm = useForm<CustomerSchema>({
    resolver: zodResolver(customerSchema),
  })

  const { formState } = paymentForm

  useEffect(() => {
    ;(async () => {
      const { data, status } = await apiPay.get<Transaction>(
        `/transactions/${code}`,
      )

      if (status === 200) {
        const isTicket = data.transactionType === 'ticket'
        if (isTicket) {
          const responseTicket = await apiPay.get<Ticket>(
            `/tickets/transactions/${data.id}`,
          )

          if (responseTicket.status === 200) {
            if (data.statusPayment !== 'success') {
              setInfoMessage(
                'Essa transação já possui os dados da conta enviado, caso queira pagar outra conta, peça um novo link de pagamento',
              )
            } else {
              setTransactionAlreadyPaid(true)
            }
            setTypeForm('payment')
          } else if (responseTicket.status === 404) {
            setTypeForm('files')
          }
        } else {
          setTypeForm('payment')
        }

        setTransaction(data)
      }

      if (data.statusPayment === 'success') {
        setTransactionAlreadyPaid(true)
      }

      if (data.statusPayment === 'expired') {
        setTransactionExpired(true)
      }
    })()
  }, [code])

  const sendTicket = async (data: CustomerSchema) => {
    try {
      const bodyObj = {
        companyId: transaction?.companyId,
        transactionId: transaction?.id,
        name: data.name,
        phoneNumber: data.phoneNumber,
        document: data.document,
      }
      const body = new FormData()

      files.forEach((file) => {
        body.append('files', file)
      })

      for (const [key, value] of Object.entries(bodyObj)) {
        body.append(key, value || '')
      }

      const { status } = await apiPay.post('/tickets', body)

      if (status === 409) {
        setPaymentDeniedMessage(
          'Essa transação já possui arquivos, caso queira pagar outra conta, peça um novo link de pagamento',
        )
        return
      }

      customerForm.reset()
      files.length = 0

      setTypeForm('payment')
    } catch (error) {}
  }

  const handleClose = () => {
    setTypeForm('payment')
    setPaymentSuccess(false)
  }

  const onSubmit = async (data: PaymentSchema) => {
    try {
      const { data: response, status } = await apiPay.post(
        '/transactions/payment',
        {
          codeTransaction: code,
          cardHolderName: data.cardName,
          cardNumber: data.numberCard,
          expirationDate: data.expireDate,
          securityCode: data.securityCode,
        },
      )
      if (status === 405) {
        setPaymentDeniedMessage(response.Message)
        return
      }
      if (status !== 200) {
        setPaymentDeniedMessage('Houve um erro inesperado, tente novamente')
        return
      }
      paymentForm.reset()
      setPaymentSuccess(true)
    } catch (error) {
      setPaymentDeniedMessage('Houve um erro inesperado, tente novamente')
    }
  }

  const handleClosePaymentError = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setPaymentDeniedMessage('')
  }

  const handleCloseInfoMessage = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setInfoMessage('')
  }

  const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `

  const chooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files: filesSelect } = event.target
    if (!filesSelect) return
    const types = ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf']

    const filesTemp: File[] = []

    for (let i = 0; i < filesSelect.length; i++) {
      if (types.includes(filesSelect[i].type)) {
        filesTemp.push(filesSelect[i])
      } else {
        setPaymentDeniedMessage(
          'É permitido somente arquivos dos tipos PNG, JPEG e PDF',
        )
      }
    }

    setFiles([...files, ...filesTemp])
  }

  const removeFile = (file: File) => {
    setFiles(files.filter((f) => f.name !== file.name))
  }

  return (
    <main className="client-link-payment-container">
      <header>
        <section>
          <h1>Helpmaney</h1>
          <h1>{formatPrice(transaction?.totalPayment || 0)}</h1>
          <h1>{`${transaction?.numberInstallments}x de ${formatPrice(
            transaction?.installmentValue || 0,
          )}`}</h1>
        </section>
        <img src={Logo} alt="HelpManey" id="logo" />
      </header>
      {transactionAlreadyPaid || transactionExpired ? (
        <>
          {transactionAlreadyPaid ? (
            <h3>Transação já paga :)</h3>
          ) : (
            <h3>Transação expirada :(</h3>
          )}
        </>
      ) : (
        <>
          {typeForm === 'files' && (
            <>
              <div className="container-uploader">
                <div className="list-files">
                  <div className="files">
                    {files.map((file) => (
                      <div className="file" key={file.name}>
                        <p>{file.name}</p>
                        <button type="button" onClick={() => removeFile(file)}>
                          <BsTrashFill color="#343a41" size={25} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  href="#file-upload"
                  color="success"
                >
                  Selecionar arquivos de sua conta
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    onChange={chooseFile}
                    accept="image/png,image/jpg,application/pdf"
                  />
                </Button>
              </div>
              <button
                className="button-avance"
                type="button"
                onClick={() => {
                  if (files.length === 0) {
                    setPaymentDeniedMessage('Selecione pelo menos um arquivo')
                    return
                  }
                  setTypeForm('customer')
                }}
              >
                Avançar
              </button>
            </>
          )}
          {typeForm === 'customer' && (
            <>
              <button
                type="button"
                className="button-back"
                onClick={() => setTypeForm('files')}
              >
                <MdOutlineKeyboardBackspace size={30} color="#343a41" />
                Voltar
              </button>
              <FormProvider {...customerForm}>
                <form onSubmit={customerForm.handleSubmit(sendTicket)}>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="name"
                      label="Seu nome"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      error={!!customerForm.formState.errors.name?.message}
                      id="outlined-error"
                    />
                  </Form.Field>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="phoneNumber"
                      label="Seu número de telefone"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      mask="(99) 9 9999-9999"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      error={
                        !!customerForm.formState.errors.phoneNumber?.message
                      }
                      id="outlined-error"
                    />
                  </Form.Field>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="document"
                      label="Seu CPF"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      mask="999.999.999-99"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      error={!!customerForm.formState.errors.document?.message}
                      id="outlined-error"
                    />
                  </Form.Field>
                  <button className="button-avance" type="submit">
                    {customerForm.formState.isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Avançar para pagamento'
                    )}
                  </button>
                </form>
              </FormProvider>
            </>
          )}

          {typeForm === 'payment' && (
            <>
              {/* {isTicketPayment && (
                <button
                  type="button"
                  className="button-back"
                  onClick={() => setTypeForm('customer')}
                >
                  <MdOutlineKeyboardBackspace size={30} color="#343a41" />
                  Voltar
                </button>
              )} */}
              <FormProvider {...paymentForm}>
                <form onSubmit={paymentForm.handleSubmit(onSubmit)}>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="cardName"
                      label="Nome no cartão"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      error={!!formState.errors.cardName?.message}
                      id="outlined-error"
                    />
                  </Form.Field>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="numberCard"
                      label="Número cartão"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      mask="9999 9999 9999 9999"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      error={!!formState.errors.numberCard?.message}
                      id="outlined-error"
                    />
                  </Form.Field>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="expireDate"
                      label="Data de expiração"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      mask="99/99"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      error={!!formState.errors.expireDate?.message}
                      id="outlined-error"
                    />
                  </Form.Field>
                  <Form.Field className="container-input">
                    <Form.Input
                      name="securityCode"
                      label="CVV"
                      size="small"
                      className="w-100"
                      variant="filled"
                      color="success"
                      mask="999"
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      error={!!formState.errors.securityCode?.message}
                      id="outlined-error"
                    />
                  </Form.Field>
                  <button type="submit">
                    {formState.isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Confirmar pagamento'
                    )}
                  </button>
                </form>
              </FormProvider>
            </>
          )}
        </>
      )}

      <Snackbar
        open={!!paymentDeniedMessage}
        autoHideDuration={5000}
        onClose={handleClosePaymentError}
      >
        <Alert
          severity={'error'}
          sx={{ width: '100%', fontSize: 18, textAlign: 'center' }}
          onClose={handleClosePaymentError}
        >
          {paymentDeniedMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!infoMessage}
        autoHideDuration={5000}
        onClose={handleCloseInfoMessage}
      >
        <Alert
          severity={'info'}
          sx={{ width: '100%', fontSize: 18, textAlign: 'center' }}
          onClose={handleCloseInfoMessage}
        >
          {infoMessage}
        </Alert>
      </Snackbar>

      <ModalReceipt
        isOpen={paymentSuccess || transactionAlreadyPaid}
        handleClose={handleClose}
        transactionCode={code || ''}
      ></ModalReceipt>
    </main>
  )
}
