import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import { Skeleton, TableBody, useMediaQuery } from '@mui/material'
import { Ticket } from '../../entities/ticket'
import { ListPagination } from '../../components/ListPagination'
import { useState } from 'react'
import { useNavigate } from 'react-router'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 15,
  paddingBottom: 15,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#16947B',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const RowTableCell = styled(TableCell)(() => ({
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 10,
  paddingBottom: 10,
}))

interface Props {
  loading: boolean
  tickets: Ticket[]
}

const defineStatusTicket = (
  ticketStatus: string,
  transactionStatus: string,
) => {
  if (transactionStatus === 'waiting') {
    return 'Aguardando pagamento do cliente'
  }

  if (transactionStatus === 'success' && ticketStatus === 'waiting') {
    return 'Aguardando confirmação do pagamento do boleto'
  }

  if (transactionStatus === 'expired') {
    return 'Pagamento expirou'
  }

  if (transactionStatus === 'denied') {
    return 'Pagamento negado'
  }

  if (transactionStatus === 'success' && ticketStatus === 'done') {
    return 'Concluído'
  }

  return ''
}

function RowTicket({ ticket }: { ticket: Ticket }) {
  const isMobile = useMediaQuery('(max-width:768px)')
  const navigate = useNavigate()
  const [year, month, day] = ticket.createdAt.split('T')[0].split('-')
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      {!isMobile && (
        <RowTableCell align="center">{ticket.id.split('-')[0]}</RowTableCell>
      )}
      <RowTableCell align="center">{`${day}/${month}/${year}`}</RowTableCell>
      {!isMobile && (
        <RowTableCell align="center">{ticket.customerName}</RowTableCell>
      )}
      <RowTableCell align="center">
        {defineStatusTicket(
          ticket.ticketStatus,
          ticket.transactionPaymentStatus,
        )}
      </RowTableCell>
      <RowTableCell
        align="center"
        sx={{
          maxWidth: '100px',
        }}
      >
        <button
          type="button"
          onClick={() => navigate(`/tickets/${ticket.id}`)}
          style={{
            color: '#16947B',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          Ver detalhes
        </button>
      </RowTableCell>
    </TableRow>
  )
}

export function TicketsTable({ loading, tickets }: Props) {
  const isMobile = useMediaQuery('(max-width:768px)')
  const rowsPerPage = isMobile ? 10 : 5
  const [page, setPage] = useState(0)

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: !isMobile ? 1000 : '95%',
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            {loading ? (
              <TableRow>
                <StyledTableCell align="center">
                  <Skeleton
                    animation="wave"
                    width={'90%'}
                    sx={{
                      marginLeft: '5%',
                    }}
                  />
                </StyledTableCell>
              </TableRow>
            ) : (
              <TableRow>
                {!isMobile && (
                  <StyledTableCell align="center">Código</StyledTableCell>
                )}
                <StyledTableCell align="center">Data</StyledTableCell>
                {!isMobile && (
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                )}
                <StyledTableCell align="center">Status boleto</StyledTableCell>
                <StyledTableCell align="center" />
              </TableRow>
            )}
          </TableHead>
          {loading ? (
            <TableBody>
              {[1, 2, 3, 4, 5].map((i) => (
                <TableRow key={i} sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <Skeleton
                    animation="wave"
                    width={'95%'}
                    height={50}
                    sx={{
                      marginLeft: '2%',
                    }}
                  />
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {tickets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((ticket) => (
                  <RowTicket key={ticket.id} ticket={ticket} />
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ListPagination
        isMobile={isMobile}
        lenghtList={tickets.length}
        loading={loading}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
      />
    </>
  )
}
