import * as React from 'react'
import { SimulatedTransaction } from '../../entities/transaction'
import { formatPrice } from '../../helpers/format'

import './styles.css'

interface Props {
  transactions: SimulatedTransaction[]
  setInsallments: (value: string) => void
  className?: string
}

export const TableInstallments: React.FC<Props> = ({
  transactions,
  setInsallments,
  className,
}: Props) => (
  <div className={`table-installments ${className}`}>
    <table>
      <thead>
        <tr>
          <th />
          <th>parcelas</th>
          <th>total à pagar</th>
          <th>valor liberado</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction) => (
          <tr key={transaction.numberInstallments}>
            <td>
              <input
                type="radio"
                value={transaction.numberInstallments}
                name="installments"
                onChange={(e) => setInsallments(e.target.value)}
              />
            </td>
            <td>{`${transaction.numberInstallments}x ${formatPrice(
              transaction.installmentValue,
            )}`}</td>
            <td>{formatPrice(transaction.totalPayment)}</td>
            <td>{formatPrice(transaction.amountReceived)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
