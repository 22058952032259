import axios from 'axios'
import { getCookie } from '../helpers/cookies'

const apiPay = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => status < 500,
})

apiPay.interceptors.request.use((request) => {
  const token = getCookie('token')

  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }

  return request
})

apiPay.interceptors.response.use((response) => {
  if (response.status === 401 && window.location.pathname !== '/login') {
    window.location.href = '/login'
  }

  return response
})

export default apiPay
