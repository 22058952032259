import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import './styles.css'
import { FormRangeDate } from '../../components/FormRangeDate'
import { TicketsTable } from './ticketsTable'
import apiPay from '../../services/apiPay'
import { Ticket } from '../../entities/ticket'
import { useQuery } from 'react-query'

const listReceiptsSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
})

type ListReceiptsSchema = z.infer<typeof listReceiptsSchema>

export default function Tickets() {
  const today = new Date()
  const listTicktesForm = useForm<ListReceiptsSchema>({
    resolver: zodResolver(listReceiptsSchema),
    defaultValues: {
      startDate: new Date(today.setDate(today.getDate() - 7)),
      endDate: new Date(),
    },
  })

  const { watch } = listTicktesForm

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const { data: tickets, isFetching: loading } = useQuery(
    `tickets:${startDate.getFullYear()}-${startDate.getMonth() + 1}-${
      startDate.getDate() - 1
    }-${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
      endDate.getDate() + 2
    }`,
    async () => {
      const { data } = await apiPay.get<{
        tickets: Ticket[]
      }>('/tickets', {
        params: {
          startDate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${
            startDate.getDate() - 1
          }`,
          endDate: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
            endDate.getDate() + 2
          }`,
        },
      })

      return data.tickets
    },
    {
      staleTime: 1000 * 60 * 5,
    },
  )

  return (
    <div className="container-tickets">
      <h2>Lista de contas</h2>

      <FormProvider {...listTicktesForm}>
        <FormRangeDate />
      </FormProvider>

      <TicketsTable loading={loading} tickets={tickets || []} />
    </div>
  )
}
