import { TablePagination } from '@mui/material'
import { CiFolderOff } from 'react-icons/ci'

interface Props {
  lenghtList: number
  loading: boolean
  isMobile: boolean
  rowsPerPage: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export function ListPagination({
  lenghtList,
  loading,
  isMobile,
  page,
  setPage,
  rowsPerPage,
}: Props) {
  if (loading) return null

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  if (lenghtList > 0) {
    return (
      <TablePagination
        component="div"
        count={lenghtList}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        page={page}
        onPageChange={handleChangePage}
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 30,
      }}
    >
      <CiFolderOff size={!isMobile ? '10%' : '20%'} color="#cdcdcd" />
      <p style={{ color: '#cdcdcd' }}>Você não possui transações</p>
    </div>
  )
}
