import { ReactElement } from 'react'
import { formatPrice } from '../../helpers/format'

interface StatementItemProps {
  children: ReactElement
  date: string
  amount: number
}

export function StatementItem({ children, date, amount }: StatementItemProps) {
  return (
    <div className="statement__item">
      {children}
      <p>{new Date(date).toLocaleDateString('pt-BR')}</p>
      <p>{formatPrice(amount)}</p>
    </div>
  )
}
