import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'

interface CustomProps {
  name: string
}

type SelectProps = CustomProps & MuiSelectProps

export function Select(props: SelectProps) {
  const { register } = useFormContext()

  return (
    <MuiSelect
      id={props.name}
      {...register(props.name)}
      defaultValue={props.defaultValue}
      {...props}
    />
  )
}
