import { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import { drawerWidth } from '.'
import { useMediaQuery } from '@mui/material'

import Logo from '../../../assets/logo.svg'

interface MenuHeaderProps {
  open: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: '#16947B',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export function MenuHeader({ open, setOpen }: MenuHeaderProps) {
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const handleDrawerOpen = () => {
    if (!setOpen) return
    setOpen(true)
  }
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {setOpen && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          noWrap
          component="div"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: 'auto',
            fontSize: isMobile ? '1.3rem' : '1.5rem',
          }}
        >
          <img
            src={Logo}
            style={{
              marginRight: 10,
            }}
            alt="Logo"
            width={isMobile ? 30 : 40}
            height={isMobile ? 30 : 40}
          />
          HelpManey
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
