import { ReactNode, useEffect, useState } from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import HomeIcon from '@mui/icons-material/Home'
import ReceiptIcon from '@mui/icons-material/Receipt'
import LinkIcon from '@mui/icons-material/Link'
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import PersonIcon from '@mui/icons-material/Person'
import { MenuItem } from './MenuItem'
import { MenuHeader } from './MenuHeader'

import './style.css'
import { useMediaQuery } from '@mui/material'

export const services = [
  {
    name: 'Parcelar Conta',
    icon: <ReceiptIcon />,
    href: '/payment-ticket',
  },
  {
    name: 'Link de pagamento',
    icon: <LinkIcon />,
    href: '/link-payment',
  },
  // {
  //   name: 'Planos de assinaturas',
  //   icon: <CurrencyExchangeIcon />,
  //   href: '/',
  // },
]

export const analisys = [
  {
    name: 'Transações',
    icon: <PointOfSaleIcon />,
    href: '/transactions',
  },
  // {
  //   name: 'Assinaturas',
  //   icon: <BusinessCenterIcon />,
  //   href: '/',
  // },
  {
    name: 'Recebimentos',
    icon: <AccountBalanceIcon />,
    href: '/receipts',
  },
  {
    name: 'Contas',
    icon: <ReceiptLongIcon />,
    href: '/tickets',
  },
]

export const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: '#16947B',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export function MenuDesktop({ children }: { children: ReactNode }) {
  const toOpen = useMediaQuery('(min-width: 1300px)')
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(toOpen)
  }, [toOpen])

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box id="desktop-menu" sx={{ display: 'flex' }}>
      <CssBaseline />
      <MenuHeader open={open} setOpen={setOpen} />
      <Drawer variant="permanent" open={open} sx={{ position: 'relative' }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon
              style={{
                color: '#fff',
              }}
            />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <MenuItem open={open} icon={<HomeIcon />} href="/" name="Home" />
        </List>
        <Divider />
        <List>
          {services.map((service) => (
            <MenuItem
              key={service.name}
              open={open}
              icon={service.icon}
              href={service.href}
              name={service.name}
            />
          ))}
        </List>
        <Divider />
        <List>
          {analisys.map((service) => (
            <MenuItem
              key={service.name}
              open={open}
              icon={service.icon}
              href={service.href}
              name={service.name}
            />
          ))}
        </List>
        <Divider />
        <List>
          <MenuItem
            open={open}
            icon={<PersonIcon />}
            href="/profile"
            name="Perfil"
          />
        </List>
      </Drawer>
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <DrawerHeader style={{ opacity: 0 }} />
        {children}
      </main>
    </Box>
  )
}
