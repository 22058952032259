import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import { useFormContext } from 'react-hook-form'

interface CustomProps {
  value: string
  name: string
}

type RadioOptionProps = CustomProps & FormControlLabelProps

export function RadioOption(props: RadioOptionProps) {
  const { register } = useFormContext()
  return <FormControlLabel {...register(props.name)} {...props} />
}
