import { CircularProgress, Snackbar, Alert } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import copy from 'copy-to-clipboard'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { BiSolidCopy } from 'react-icons/bi'
import { MdOutlineDoneOutline } from 'react-icons/md'

import './styles.css'
import { treatStrings } from '../../helpers/format'
import { Form } from '../../components/Form'

const sendLinkSchema = z.object({
  customerPhone: z.string().nonempty({ message: 'O telefone é obrigatório' }),
})

type SendLinkPaymentSchema = z.infer<typeof sendLinkSchema>

export default function SendLinkPayment() {
  const navigate = useNavigate()
  const { code } = useParams()
  const [sending, setSending] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isSafariApple, setIsSafariApple] = useState(false)

  const sendLinkForm = useForm<SendLinkPaymentSchema>({
    resolver: zodResolver(sendLinkSchema),
  })

  const hrefApiWhatsRef = useRef<HTMLAnchorElement>(null)

  const linkPayment = `${window.origin}/link-payment/client/${code}`

  const copyClick = async () => {
    copy(linkPayment)

    setCopied(true)
  }

  const { handleSubmit, formState } = sendLinkForm

  const customerPhone = sendLinkForm.watch('customerPhone')

  const onSubmit = async () => {
    setSending(true)

    hrefApiWhatsRef.current?.click()
    setSending(false)
    navigate('/')
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setCopied(false)
  }

  useEffect(() => {
    const isAppleDevice = /iphone|ipod|ipad/i.test(navigator.userAgent)
    if (isAppleDevice) {
      const isChrome = /CriOS/i.test(navigator.userAgent)
      setIsSafariApple(!isChrome)
    }
  }, [])

  return (
    <div className="container-send-link-payment">
      <h2>Envio de link</h2>
      <section>
        <span>{`Aqui está o link para seu cliente, 
        clique no botão ao lado para copiar e enviar manualmente ou envie via whatsappp`}</span>
        <div>
          <p>{linkPayment}</p>
          <button type="button" onClick={copyClick}>
            {!copied ? (
              <BiSolidCopy size={20} color="#16947B" />
            ) : (
              <MdOutlineDoneOutline size={20} color="#16947B" />
            )}
          </button>
        </div>
      </section>
      <FormProvider {...sendLinkForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field className="w-100">
            <Form.Input
              name="customerPhone"
              label="Número do cliente"
              size="small"
              className="w-100"
              color="success"
              autoComplete="off"
              error={!!formState.errors.customerPhone?.message}
              id="outlined-error"
              mask="(99) 9 9999-9999"
              inputProps={{
                inputMode: 'decimal',
              }}
            />
          </Form.Field>
          <div className="container-buttons">
            <button
              type="button"
              disabled={sending}
              onClick={() => navigate('/link-payment')}
            >
              {sending ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'voltar'
              )}
            </button>
            <button type="submit" disabled={sending}>
              {sending ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'enviar via WhatsApp'
              )}
            </button>
          </div>
        </form>
      </FormProvider>
      <Snackbar open={copied} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={'success'}
          sx={{ width: '100%' }}
        >
          {'Link copiado!'}
        </Alert>
      </Snackbar>
      <a
        href={`https://api.whatsapp.com/send?phone=55${encodeURIComponent(
          treatStrings(customerPhone || ''),
        )}&text=${linkPayment}`}
        target={isSafariApple ? '_self' : '_blank'}
        rel="noopener noreferrer"
        ref={hrefApiWhatsRef}
        id="a-link"
      />
    </div>
  )
}
