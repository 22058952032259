import { JSX } from 'react'
import './styles.css'
import { BiLoaderCircle } from 'react-icons/bi'
import { useNavigate } from 'react-router'
import { StatementItem } from './statementItem'
import InfoIcon from '@mui/icons-material/Info'
import { IoMdClose } from 'react-icons/io'
import { GoVerified } from 'react-icons/go'
import { MdTimerOff } from 'react-icons/md'
import { GiTakeMyMoney } from 'react-icons/gi'
import { QuickMenu } from './quickMenu'
import { Balance, Transaction } from '../../entities/transaction'
import { Receipt } from '../../entities/receipt'
import { User } from '../../entities/user'
import { getCookie } from '../../helpers/cookies'
import apiPay from '../../services/apiPay'
import { formatPrice } from '../../helpers/format'
import { Skeleton, Tooltip } from '@mui/material'
import { useQuery } from 'react-query'

const statusIconMapper: Record<string, JSX.Element> = {
  waiting: <BiLoaderCircle color="#1890ff" size={25} />,
  success: <GoVerified color="#09b40d" size={25} />,
  expired: <MdTimerOff color="#d34242" size={25} />,
}

interface HomeData {
  balance: Balance
  transactions: Transaction[]
  receipts: Receipt[]
  user: User
}

const getHomeData = async (): Promise<HomeData | undefined> => {
  const sessionToken = getCookie('token')

  if (!sessionToken) {
    return undefined
  }

  const [receiptResponse, userResponse, balanceResponse, transactionResponse] =
    await Promise.all([
      apiPay.get<{ receipts: Receipt[] }>('/receipts'),
      apiPay.get<{ user: User }>('/users/show'),
      apiPay.get<Balance>('/transactions/balance'),
      apiPay.get<{ transactions: Transaction[] }>('/transactions?limit=5'),
    ])

  if (
    [
      balanceResponse.status,
      receiptResponse.status,
      transactionResponse.status,
      userResponse.status,
    ].includes(401)
  ) {
    return undefined
  }

  return {
    balance: balanceResponse.data || { balance: 0, amountReceivable: 0 },
    transactions: transactionResponse.data?.transactions || [],
    receipts: receiptResponse.data?.receipts.slice(0, 5) || [],
    user: userResponse.data?.user || ({} as User),
  }
}

export default function PayHome() {
  const navigate = useNavigate()
  const { data, isFetching: loadData } = useQuery(
    'home',
    async () => {
      const data = await getHomeData()

      if (data) {
        return data
      }

      navigate('/login')
    },
    {
      staleTime: 1000 * 60,
    },
  )

  return (
    <div className="home">
      <div className="welcome">
        {loadData ? (
          <Skeleton width="200px" animation="wave" />
        ) : (
          <p>{`Olá, ${data?.user?.username || ''}`}</p>
        )}
      </div>
      <div className="balance card__home">
        <div className="balance__value">
          <p className="balance_title">Vendas do mês</p>
          {loadData ? (
            <Skeleton width="100px" animation="wave" />
          ) : (
            <p className="balance_title">{`${formatPrice(
              data?.balance?.balance || 0,
            )}`}</p>
          )}

          <span className="balance_secondary">
            {loadData ? (
              <>
                <Skeleton width="100px" animation="wave" />
              </>
            ) : (
              <>
                <p>{`${formatPrice(data?.balance?.amountReceivable || 0)}`}</p>
                <Tooltip title="saldo à receber" placement="right">
                  <InfoIcon color="action" fontSize="small" />
                </Tooltip>
              </>
            )}
          </span>
        </div>

        <button type="button" onClick={() => navigate('/transactions')}>
          {'Ver transações >'}
        </button>
      </div>

      <QuickMenu />

      <div className="activity">
        <div className="last_transactions card__home">
          <div className="transactions__header">
            <p>Últimas atividades</p>
            <hr />
          </div>
          <div className={`statement__body}`}>
            {loadData ? (
              [1, 2, 3, 4, 5].map((v) => <Skeleton key={v} animation="wave" />)
            ) : (
              <>
                {data?.transactions?.length ? (
                  data?.transactions.map((transaction) => (
                    <StatementItem
                      key={transaction.id}
                      amount={transaction.amountSale}
                      date={transaction.createdAt}
                    >
                      {statusIconMapper[transaction.statusPayment] || (
                        <IoMdClose color="#d34242" size={25} />
                      )}
                    </StatementItem>
                  ))
                ) : (
                  <p>Você não possui nenhuma atividade :(</p>
                )}
              </>
            )}
          </div>
        </div>

        <div className="last_receiveds card__home">
          <div className="receiveds__header">
            <p>Últimas recebimentos</p>
            <hr />
          </div>
          <div className={`statement__body`}>
            {loadData ? (
              [1, 2, 3, 4, 5].map((v) => <Skeleton key={v} animation="wave" />)
            ) : (
              <>
                {data?.receipts?.length ? (
                  data?.receipts.map((received) => (
                    <StatementItem
                      key={received.id}
                      amount={received.amountPaid}
                      date={received.createdAt}
                    >
                      <GiTakeMyMoney color="#09b40d" size={25} />
                    </StatementItem>
                  ))
                ) : (
                  <p>Você não possui nenhum recebimento :(</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
