'use client'

import * as React from 'react'

import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { MenuHeader } from '../MenuDesktop/MenuHeader'
import HomeIcon from '@mui/icons-material/Home'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import PersonIcon from '@mui/icons-material/Person'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

import './styles.css'
import { useNavigate } from 'react-router'

interface Props {
  window?: () => Window
  children: React.ReactNode
}

const menuOtions = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    href: '/',
  },
  {
    name: 'Transações',
    icon: <PointOfSaleIcon />,
    href: '/transactions',
  },
  {
    name: 'Serviços',
    icon: <CurrencyExchangeIcon />,
    href: '/services',
  },
  {
    name: 'Recebimentos',
    icon: <AccountBalanceIcon />,
    href: '/receipts',
  },
  {
    name: 'Perfil',
    icon: <PersonIcon />,
    href: '/profile',
  },
]

export function MenuMobile(props: Props) {
  const navigate = useNavigate()
  const [value, setValue] = React.useState('recents')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div id="mobile-menu">
      <CssBaseline />
      <MenuHeader open={false} />
      <Toolbar />
      <Container
        sx={{
          height: 'auto',
          overflowX: 'hidden',
          padding: 0,
        }}
      >
        <Box
          sx={{
            marginBottom: 10,
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          {props.children}
        </Box>
      </Container>
      <BottomNavigation
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          background: '#16947B',
        }}
        value={value}
        onChange={handleChange}
      >
        {menuOtions.map((option) => (
          <BottomNavigationAction
            key={option.name}
            showLabel={true}
            label={option.name}
            value={option.name}
            icon={option.icon}
            style={{
              color: '#fff',
            }}
            onClick={() => navigate(option.href)}
          />
        ))}
      </BottomNavigation>
    </div>
  )
}
