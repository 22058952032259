import { z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import ReceiptsTable from './receiptsTable'
import apiPay from '../../services/apiPay'

import { Receipt } from '../../entities/receipt'
import { FormRangeDate } from '../../components/FormRangeDate'
import { useQuery } from 'react-query'

const listReceiptsSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
})

type ListReceiptsSchema = z.infer<typeof listReceiptsSchema>

export default function ListReceipts() {
  const today = new Date()
  const listReceiptsForm = useForm<ListReceiptsSchema>({
    resolver: zodResolver(listReceiptsSchema),
    defaultValues: {
      startDate: new Date(today.setDate(today.getDate() - 7)),
      endDate: new Date(),
    },
  })

  const { watch } = listReceiptsForm

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const { data: receipts, isFetching: loading } = useQuery(
    `listReceipts:${startDate.getFullYear()}-${startDate.getMonth() + 1}-${
      startDate.getDate() - 1
    }-${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
      endDate.getDate() + 2
    }`,
    async () => {
      const [receiptsResponse] = await Promise.all([
        apiPay.get<{ receipts: Receipt[] }>('/receipts', {
          params: {
            startDate: `${startDate.getFullYear()}-${
              startDate.getMonth() + 1
            }-${startDate.getDate() - 1}`,
            endDate: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${
              endDate.getDate() + 2
            }`,
          },
        }),
      ])

      const { data } = receiptsResponse

      return data
    },
    {
      staleTime: 1000 * 60 * 10,
    },
  )

  return (
    <div className="container-transactions">
      <h2>Recebimentos</h2>

      <FormProvider {...listReceiptsForm}>
        <FormRangeDate />
      </FormProvider>

      <ReceiptsTable receipts={receipts?.receipts || []} loading={loading} />
    </div>
  )
}
