import { Routes, Route } from 'react-router-dom'
import Dashboard from './pages/dashboard'
import Login from './pages/Login'
import LinkPayment from './pages/LinkPayment'
import PaymenttTicket from './pages/PaymenttTicket'
import ListTransactions from './pages/ListTransactions'
import ListReceipts from './pages/ListReceipts'
import { PrivateRoute } from './components/PrivateRoute'
import Profile from './pages/Profile'
import ClientLinkPayment from './pages/ClientLinkPayment'
import SendLinkPayment from './pages/SendLinkPayment'
import Tickets from './pages/Tickets'
import TicketDetails from './pages/TicketDetails'
import Services from './pages/Services'

export function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/link-payment"
        element={
          <PrivateRoute>
            <LinkPayment />
          </PrivateRoute>
        }
      />
      <Route
        path="/link-payment/send/:code"
        element={
          <PrivateRoute>
            <SendLinkPayment />
          </PrivateRoute>
        }
      />
      <Route
        path="/link-payment/client/:code"
        element={<ClientLinkPayment />}
      />
      <Route
        path="/payment-ticket"
        element={
          <PrivateRoute>
            <PaymenttTicket />
          </PrivateRoute>
        }
      />
      <Route
        path="/transactions"
        element={
          <PrivateRoute>
            <ListTransactions />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets"
        element={
          <PrivateRoute>
            <Tickets />
          </PrivateRoute>
        }
      />
      <Route
        path="/tickets/:id"
        element={
          <PrivateRoute>
            <TicketDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/receipts"
        element={
          <PrivateRoute>
            <ListReceipts />
          </PrivateRoute>
        }
      />

      <Route
        path="/services"
        element={
          <PrivateRoute>
            <Services />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  )
}
