import { Modal } from '@mui/base'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import apiPay from '../../services/apiPay'

interface Props {
  isOpen: boolean
  handleClose: () => void
  transactionCode: string
}

export function ModalReceipt({ isOpen, handleClose, transactionCode }: Props) {
  const [loading, setLoading] = useState(false)

  const sendReceipt = async () => {
    try {
      setLoading(true)
      const { status, data } = await apiPay.post('/transactions/receipt', {
        transactionCode,
      })

      if (status === 200) {
        const receiptUri = data.receiptUri
        const link = document.createElement('a')
        link.href = receiptUri
        link.download = 'recibo.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
    } finally {
      handleClose()
      setLoading(false)
    }
  }
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box id="container-modal">
        <h3>Pagamento efetuado com sucesso</h3>
        <span>Deseja baixar o recibo? clique no botão abaixo</span>
        <button onClick={sendReceipt} disabled={loading}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Baixar recibo'
          )}
        </button>
      </Box>
    </Modal>
  )
}
