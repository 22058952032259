import DatePicker, { registerLocale } from 'react-datepicker'

import pt from 'date-fns/locale/pt'

import 'react-datepicker/dist/react-datepicker.css'
import { useFormContext, Controller } from 'react-hook-form'

interface Props {
  name: string
}

export function DateInput({ name }: Props) {
  const { control } = useFormContext()
  registerLocale('pt', pt)
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          selected={value}
          onChange={onChange}
          locale="pt"
          dateFormat="dd/MM/yyyy"
        />
      )}
    />
  )
}
