import { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import './styles.css'
import apiPay from '../../services/apiPay'
import { User } from '../../entities/user'
import { Company } from '../../entities/company'
import { Card, CardContent, useMediaQuery } from '@mui/material'
import { useQuery } from 'react-query'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Profile() {
  const [value, setValue] = useState(0)
  const isMobile = useMediaQuery('(max-width: 1024px)')

  const { data } = useQuery(
    'profile',
    async () => {
      const [userResponse, companyResponse] = await Promise.all([
        apiPay.get<{ user: User }>('/users/show'),
        apiPay.get<Company>('/companies'),
      ])

      return {
        user: userResponse.data.user,
        company: companyResponse.data,
      }
    },
    {
      staleTime: 1000 * 60 * 60,
    },
  )

  const user = data?.user
  const company = data?.company

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: isMobile ? '100%' : '1000px' }}>
      <h2 className="title-profile">Perfil</h2>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label={
            !isMobile ? 'scrollable auto tabs example' : 'basic tabs example'
          }
        >
          <Tab label="Dados pessoais" {...a11yProps(0)} />
          <Tab label="Empresa" {...a11yProps(1)} />
          <Tab label="Endereço" {...a11yProps(2)} />
          <Tab label="Dados bancários" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography color="text.secondary" variant="body2">
              Nome
            </Typography>
            <Typography
              sx={{ fontSize: 16 }}
              color="text.secondary"
              gutterBottom
            >
              {user?.name}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Username
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {user?.username}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Email
            </Typography>
            <Typography variant="body2">{user?.email}</Typography>
          </CardContent>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography color="text.secondary" variant="body2">
              Razão social
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.socialReason}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Nome fantasia
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.fantasyName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Segmento
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.segment}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Tipo
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.type.toLowerCase() === 'f'
                ? 'Pessoa Física'
                : 'Pessoa Jurídica'}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {company?.type.toLowerCase() === 'f' ? 'CPF' : 'CNPJ'}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.document}
            </Typography>

            <Typography color="text.secondary" variant="body2">
              Email
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.email}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Telefone
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.phoneNumber}
            </Typography>
          </CardContent>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography color="text.secondary" variant="body2">
              Endereço
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {`${company?.address.address} N ${company?.address.number} - ${company?.address.neighborhood}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Cidade e Estado
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {`${company?.address.city}/${company?.address.state}`}
            </Typography>
          </CardContent>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            {company?.dataBank.pix && (
              <>
                <Typography color="text.secondary" variant="body2">
                  Pix
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  {company?.dataBank.pix}
                </Typography>
              </>
            )}
            <Typography color="text.secondary" variant="body2">
              Agência
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.dataBank.agency}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Conta
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.dataBank.account}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Banco
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.dataBank.bank}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Natureza
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.dataBank.accountType.toLowerCase() ===
              'checking account'
                ? 'Conta Corrente'
                : 'Conta Poupança'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {company?.dataBank.holderType.toLowerCase() === 'f'
                ? 'CPF'
                : 'CNPJ'}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              {company?.dataBank.document}
            </Typography>
          </CardContent>
        </Card>
      </CustomTabPanel>
    </Box>
  )
}
