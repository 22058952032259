import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { CiFolderOff } from 'react-icons/ci'

import { Skeleton, TablePagination, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { RowReceipt } from './rowReceipt'
import { Receipt } from '../../entities/receipt'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 15,
  paddingBottom: 15,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#16947B',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

interface Props {
  receipts: Receipt[]
  loading: boolean
}

export default function CollapsibleTable({ receipts, loading }: Props) {
  const [page, setPage] = useState(0)
  const matches = useMediaQuery('(min-width:768px)')
  const rowsPerPage = matches ? 10 : 5

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: matches ? 1000 : '95%',
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            {loading ? (
              <TableRow>
                <StyledTableCell align="center">
                  <Skeleton
                    animation="wave"
                    width={'90%'}
                    sx={{
                      marginLeft: '5%',
                    }}
                  />
                </StyledTableCell>
              </TableRow>
            ) : (
              <TableRow>
                <StyledTableCell />
                <StyledTableCell align="center">Código</StyledTableCell>
                <StyledTableCell align="center">Data</StyledTableCell>
                <StyledTableCell align="center">Valor recebido</StyledTableCell>
              </TableRow>
            )}
          </TableHead>
          {loading ? (
            <TableBody>
              {[1, 2, 3, 4, 5].map((i) => (
                <TableRow key={i} sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <Skeleton
                    animation="wave"
                    width={'95%'}
                    height={50}
                    sx={{
                      marginLeft: '2%',
                    }}
                  />
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <>
              {receipts.length > 0 && (
                <TableBody>
                  {receipts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((receipt) => (
                      <RowReceipt key={receipt.id} receipt={receipt} />
                    ))}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>

      {receipts.length > 0 ? (
        <TablePagination
          component="div"
          count={receipts.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : (
        <>
          {!loading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: 30,
              }}
            >
              <CiFolderOff size={matches ? '10%' : '20%'} color="#cdcdcd" />
              <p style={{ color: '#cdcdcd' }}>Você não possui recebimentos</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
