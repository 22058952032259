import { IoMdLink } from 'react-icons/io'
import { HiReceiptTax } from 'react-icons/hi'
import { FaReceipt } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'

const options = [
  {
    icon: <IoMdLink />,
    text: 'Link pagamento',
    href: '/link-payment',
  },
  {
    icon: <HiReceiptTax />,
    text: 'Parcele contas',
    href: '/payment-ticket',
  },
  {
    text: 'Contas',
    icon: (
      <ReceiptLongIcon
        sx={{
          fontSize: '50px',
        }}
      />
    ),
    href: '/tickets',
  },
  {
    icon: <FaReceipt />,
    text: 'Transações',
    href: '/transactions',
  },
  {
    text: 'Recebimentos',
    icon: (
      <AccountBalanceIcon
        sx={{
          fontSize: '50px',
        }}
      />
    ),
    href: '/receipts',
  },
]

export function QuickMenu() {
  const navigate = useNavigate()
  return (
    <div className="quick__options card__home">
      {options.map((option) => (
        <button
          key={option.text}
          className="quick__options__item"
          onClick={() => navigate(option.href)}
        >
          {option.icon}
          <p>{option.text}</p>
        </button>
      ))}
    </div>
  )
}
