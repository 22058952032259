import { HTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'

interface ErrorMessageProps extends HTMLAttributes<HTMLSpanElement> {
  field: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(obj: Record<any, any>, path: string) {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj,
      )

  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)

  return result
}

const fieldsMapper: Record<string, string> = {
  address: 'O Endereço',
  neighborhood: 'O Bairro',
  city: 'A Cidade',
  state: 'O Estado',
  zipcode: 'O CEP',
  number: 'O Número',
  name: 'O Nome',
  cpf: 'O CPF',
  phone: 'O Telefone',
}

export function ErrorMessage({ field, ...props }: ErrorMessageProps) {
  const {
    formState: { errors },
  } = useFormContext()

  const fieldError = get(errors, field)

  if (!fieldError) {
    return (
      <span style={{ color: '#cc0000', height: '15px', marginTop: '5px' }} />
    )
  }

  return (
    <span
      style={{
        color: '#cc0000',
        height: '15px',
        fontSize: '15px',
        marginTop: '5px',
      }}
      {...props}
    >
      {fieldError.message?.toString() === 'Required'
        ? `${fieldsMapper[field]} é obrigatório`
        : fieldError.message?.toString()}
    </span>
  )
}
