import { Form } from '../../components/Form'
import { z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import apiPay from '../../services/apiPay'
import { useNavigate } from 'react-router'
import { setCookie } from '../../helpers/cookies'

import './styles.css'

import Logo from '../../assets/logo.svg'

const loginSchema = z.object({
  username: z
    .string()
    .nonempty({ message: 'Seu nome de usuário é obrigatório' }),
  password: z.string().nonempty({ message: 'Sua senha é obrigatória' }),
})

type LoginSchema = z.infer<typeof loginSchema>

type LoginResponse = {
  token: string
}

export default function Login() {
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [autoLoginLoading, setAutoLoginLoading] = useState(false)
  const navigate = useNavigate()
  const loginForm = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  })

  const { formState } = loginForm

  const onSubmit = async (data: LoginSchema) => {
    const { username, password } = data

    try {
      const { data: responseData, status } = await apiPay.post<LoginResponse>(
        '/users/session',
        {
          usernameOrEmail: username,
          password,
        },
      )

      if (status === 200 && responseData) {
        const { token } = responseData
        setCookie('token', token)
        navigate('/')
        return
      }

      if (status === 401) {
        setFormErrorMessage('Usuário ou senha inválidos')
        return
      }

      setFormErrorMessage('Erro ao efetuar login, tente novamente')
    } catch (error) {
      setFormErrorMessage('Erro ao efetuar login, tente novamente')
    }
  }

  useEffect(() => {
    ;(async () => {
      setAutoLoginLoading(true)
      setTimeout(async () => {
        const response = await apiPay.get('/users/show')

        if (response.status === 200) {
          navigate('/')
        }
        setAutoLoginLoading(false)
      }, 1000)
    })()
  }, [navigate])

  return (
    <div className="container-login">
      <div className="column-logo">
        <div className="border">
          <img src={Logo} width={300} height={300} alt="HelpManey" id="logo" />
          <div className="helpmaney">
            <h2 id="help">HelpManey Pay</h2>
          </div>
          <p>A sua maquininha virtual</p>
        </div>
      </div>
      <div className="column-form">
        <h1>Bem vindo!</h1>
        <div className="acesse">
          <hr />
          <h4>acesse com seu usuário e senha</h4>
          <hr />
        </div>
        <FormProvider {...loginForm}>
          <form onSubmit={loginForm.handleSubmit(onSubmit)}>
            <Form.Field className="w-100">
              <Form.Input
                name="username"
                label="Usuário"
                size="small"
                className="w-100"
                variant="filled"
                color="success"
                error={!!formState.errors.username?.message}
                id="outlined-error"
                helperText={formState.errors.username?.message}
              />
            </Form.Field>
            <Form.Field className="w-100">
              <Form.Input
                className="w-100 mg-top-20"
                name="password"
                label="Senha"
                size="small"
                variant="filled"
                type="password"
                color="success"
                error={!!formState.errors.password?.message}
                id="outlined-error"
                helperText={formState.errors.password?.message}
              />
            </Form.Field>
            <button type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting || autoLoginLoading ? (
                <CircularProgress size={'1rem'} />
              ) : (
                'Entrar'
              )}
            </button>
            <p>{formErrorMessage}</p>
          </form>
        </FormProvider>
        <button
          type="button"
          id="esquece"
          // onClick={() => history.push('/forgotpassword')}
        >
          Esqueceu sua senha?
        </button>
      </div>
    </div>
  )
}
